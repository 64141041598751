export default {
  appointment: {
    type: {
      individual: '個別',
      couple: '伴侶',
      parent: '親子',
      family: '家庭',
      earlytreatment: '早療/早療團體',
    },
    locationType: {
      inhouse: '實體諮商',
      virtual: '遠距諮商',
    },
    payment: {
      notpaid: '尚未支付',
      cash: '現金支付',
      '3rdparty': '藍新金流',
      credit: '信用卡支付',
      transfer: '轉帳支付',
      project: '專案支付',
    },
    relationship: {
      single: '單身',
      inarelationship: '伴侶',
      married: '已婚',
      engaged: '訂婚',
      separate: '分居',
      divorced: '離婚',
      remarry: '再婚',
      widowed: '喪偶',
    },
    education: {
      doctor: '博士',
      master: '碩士',
      bachelor: '學士(大專院校)',
      associate: '副學士(專科)',
      seniorhigh: '高中',
      juniorhigh: '國中',
      elementary: '國小',
    },
  },
  project: {
    type: {
      mental: '心理諮詢',
      law: '法律諮詢',
      finance: '財務諮詢',
      management: '組織管理',
      medical: '健康管理',
    },
  },
  axiosErr: {
    'invalid id number': '無效的身分證字號',
    'invalid status': '已確認的財管月無法調整',
    'email has been used': 'Email 已經建立過帳號',
    'email has already been used': 'Email 已經被註冊',
    'invalid phone number': '無效的電話',
    'appointment conflicts': '同時段的空間已被預訂',
    'room has an appointment at the same time': '同時段空間已被預訂，請重新確認',
    'professional has an appointment at the same time': '專業人士同時段已經有預約',
    'id_number has been used': '身分證已重複',
    'patient has an appointment at the same time': '個案於同時間已有其他的預約',
    'invalid old password': '舊密碼錯誤',
    'insufficient attachments': '上傳個案記錄檔案不足',
    'professional has appointment or other activity': '專業人士在該時間段已有預約或其他活動',
    'room has appointment or another activity': '空間在該時間段已有預約或其他活動',
    email_conflict: 'Email 重複',
    unknown: '未知的錯誤',
    'passport has been used': '重複的護照號碼',
    'invalid gender': '無效的性別',
    'invalid education': '無效的學歷',
    'invalid relationship': '無效的感情狀態',
    'missing emergency contact information': '缺少緊急聯絡人資訊',
    'invalid emergency_contact': '無效的緊急聯絡人電話',
    // used in accounts
    'missing required fields': '缺少必須的欄位',
    'invalid role': '無效的角色',
    'admin and advstaff should not be assigned to branches': '管理員與進階個館不需要館別',
    'invalid branches': '無效的館別',
    'phone has already been used': '電話已使用',
    'advstaff tries to update admin/advstaff': '沒有權限',
    'advstaff tries to update the role to admin/advstaff': '沒有權限',
  },
  tag: {
    main_requirement: {
      relationship: '情感議題/伴侶關係',
      marriage: '婚姻與家庭',
      original_family: '原生家庭',
      friendship: '一般人際',
      career: '職涯規劃',
      colleagues: '職場人際',
      pressure: '壓力管理',
      emotion: '情緒議題',
      trauma: '失落創傷',
      psychosomatic_disorder: '身心症狀',
      sexuality: '性別議題',
      life: '生活適應',
      self_harm: '自傷與傷人',
      sex_harrassment: '性侵性騷',
      child_abuse: '家暴兒虐',
      drug_abuse: '藥物濫用',
      politcal: '政治',
      political: '政治',
      sex_gender: '性別',
      culture: '文化',
      tradition: '習俗',
      religion: '宗教與靈性',
      disability: '身心障礙',
      early_treatment: '早期療育',
      mental_other: '其他',
    },
    evaluation: {
      mental_low_risk: '低風險：無任何立即性風險',
      mental_medium_risk: '中度風險：雖有壓力但具有調管能力',
      mental_high_risk: '高度風險：需藉由持續性心理治療',
      mental_immediate_risk: '立即性風險：需通報、轉介相關單位或照會醫師',
    },
    future_plan: {
      mental_continue: '續談',
      mental_close: '結案',
      mental_transfer: '轉介',
      mental_present: '照會',
      mental_transform_to_self_pay: '方案轉自費',
      mental_future_plan_other: '其他',
    },
  },
  confirm_status: {
    not_confirmed: '尚未確認',
    confirmed: '已確認',
    wrong_amount: '金額錯誤',
    not_notified: '未通知',
  },
};
