<template lang="pug">
el-drawer(
  title="編輯預約"
  :visible.sync="drawer"
  direction="rtl"
  :before-close="handleClose"
  :append-to-body="appendToBody"
  destroy-on-close)
  .drawer.flex.column.gap-20.default
    .data.flex.column.align-stretch.gap-6
      .title 個案姓名
      el-input.input(v-model="targetName" disabled)
    .data.flex.column.align-stretch.gap-6
      .title 專案
      .desc.text-secondary 若選擇後，預約價位將依照設定預先填入，該筆預約不向個案直接收取費用
      el-select(v-model="project")
        el-option-group(
          v-for="group in organizations"
          v-if="group.public"
          :key="group.id"
          :label="group.name")
          el-option(
            v-for="p in group.projects"
            :key="p.id"
            :label="`${group.name} - ${p.name}`"
            :value="`${p.id}`")
            span {{ p.name }}
    .data.flex.column.align-stretch.gap-6
      .title 專業人士
      el-select(v-model="professional"  :disabled="!allCanEdit")
        el-option(v-for="p in professionalList",
          :key="p.id", :value="p.id", :label="`${p.family_name}${p.given_name}`")
    .data.flex.column.align-stretch.gap-6
      .title 本次諮商為
      .flex.align-center.gap-10.wrap
        el-radio.radio(v-model="type" label="individual" border  :disabled="!allCanEdit") 個別
        el-radio.radio(v-model="type" label="couple" border  :disabled="!allCanEdit") 伴侶
        el-radio.radio(v-model="type" label="parent" border  :disabled="!allCanEdit") 親子
        el-radio.radio(v-model="type" label="family" border  :disabled="!allCanEdit") 家庭
        el-radio.radio(v-model="type" label="earlytreatment" border  :disabled="!allCanEdit") 早療/早療團體
    .data.flex.column.align-stretch.gap-6(v-if="type === 'couple'")
      .title 伴侶姓名
      user-picker(
        :disabled="!allCanEdit"
        :exclude="targeID", @input="chooseRelation", ref="partnerPicker"
        :default="relationalTarget?.name || ''")
    .data.flex.column.align-stretch.gap-6(v-if="type === 'parent'")
      .title 對象
      .sub-type.flex.align-center.gap-6
        el-radio(v-model="subType", label="statutoryagents",  :disabled="!allCanEdit") 法代
        el-radio(v-model="subType", label="children",  :disabled="!allCanEdit") 子女
      user-picker(
        :exclude="targeID", @input="chooseRelation", ref="partnerPicker", :disabled="!allCanEdit"
        :default="relationalTarget?.name || ''")
    template(v-if="type === 'family' || type === 'earlytreatment'")
      .data.flex.column.align-stretch.gap-6(
        v-for="(family, idx) in relationalTargets"
        :key="`${idx}-${family.name}`")
        .title 晤談成員{{ idx + 1 }}
        .flex.align-center.gap-10
          user-picker(
            :exclude="targeID", @input="chooseFamilyRelation(idx, $event)", ref="partnersPicker" :disabled="!allCanEdit"
            :default="family?.name || family?.value || ''")
          el-button(icon="el-icon-remove-outline", @click="relationalTargets.splice(idx, 1)")
      el-button(icon="el-icon-plus", @click="addNewFamily") 新增晤談成員
    .data.flex.column.align-stretch.gap-6
      .title 預約型態
      .flex.align-center.gap-10
        el-radio.radio(v-model="locationType" label="inhouse" border) 實體諮商
        el-radio.radio(v-model="locationType" label="virtual" border) 遠距諮商
    .data.flex.column.align-stretch.gap-6(v-if="locationType === 'virtual'")
      .title 遠距諮商連結
      .flex.align-center.gap-10
        el-input.flex-1(v-model="remoteLink")
        el-button(type="primary" plain @click="generateRemoteLink") 生成連結
    .data.flex.column.align-stretch.gap-6
      .title 預約時長
      el-select(v-model="length",  :disabled="!allCanEdit")
        el-option(v-for="l in lengthOptions" :key="l", :value="l", :label="`${l}分鐘`")
    .data.flex.column.align-stretch.gap-6
      .title 預約日期
      .flex.align-center.gap-10
        el-date-picker.flex-1(
          @change="changeDate"
          v-model="startDate", type="date", placeholder="請選擇日期"
        )
        el-time-select.flex-1(
          ref='timeSelector'
          v-model="startTime"
          placeholder="請選擇時間"
          :picker-options="{start: '00:00',step: '00:30',end: '23:30'}")
    .data.flex.column.align-stretch.gap-6
      .title 場地空間
      el-select(
        v-model="branch", placeholder="請選擇館別")
        el-option(v-for="b in branchList", :key="b.id", :value="b.id", :label="b.name")
      .flex.align-center.gap-20
        el-select.flex-1(
          v-model="room",
          placeholder="請選擇空間", :disabled="branch === undefined")
          el-option(v-for="r in roomList", :key="r.id", :value="r.id", :label="r.name")
        el-button(
          :disabled="branch === undefined || startDate === undefined"
          type="text", @click="toggleRemainSpace") 查看當日空間
    .data.flex.column.align-stretch.gap-6
      .title 分帳模組
      el-select(v-model="accounting", placeholder="無"  :disabled="!allCanEdit")
        el-option(
          v-for="a in accountingList"
          v-if="a.public"
          :key="a.id", :value="a.id", :label="a.name")
    .data.flex.column.align-stretch.gap-6
      .title 預約價位
      el-input(v-model="price"  :disabled="!allCanEdit")
        template(slot="prepend") NT$
    .data.flex.column.align-stretch.gap-6
      .title 標記個案
      el-select(
        multiple, v-model="tags")
        el-option(
          v-for="tag in tagList",
          v-if="tag.public",
          :key="tag.name", :label="tag.name", :value="tag.name")
    .data.flex.column.align-stretch.gap-6
      .title 本次預約備註
      el-input(
        v-model="note"
        type="textarea", :autosize="{minRows: 3, maxRows: 3}")
    .op.flex.align-center-justify-stretch.gap-10
      el-button.flex-1(@click="cancel") 取消
      el-button.flex-1(@click="save", type="primary", :disabled="!valid") 確定
  appointment-room-view.space-view(
    :clickable="false"
    v-if="showSpace",
    :hour="showHour",
    :branch="branch", :time="startDate", public)
</template>

<script>
import { mapGetters } from 'vuex';
import { getTags } from '@/api/tags';
import { getBranches } from '@/api/place';
import { getProfessionalList } from '@/api/professionals';
import { getAccountingModels } from '@/api/accountModel';
import {
  getAppointment,
  updateAppointment,
  // generateRemoteLink,
} from '@/api/reserve';
import { getOrganizations, getOrganizationDetail } from '@/api/project';
import UserPicker from '@/components/UserPicker.vue';

export default {
  name: 'appointment-editor',
  components: {
    'user-picker': UserPicker,
  },
  props: {
    appendToBody: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      drawer: false,
      target: undefined,
      project: undefined,
      professional: undefined,
      startDate: undefined,
      startTime: '09:00',
      branch: undefined,
      room: undefined,
      accounting: undefined,
      price: undefined,
      tags: [],
      note: '',
      partner: undefined,

      relationalTarget: undefined,
      relationalTargets: [],
      remoteLink: '',
      length: undefined,
      cannotEditTarget: false,

      type: 'individual',
      subType: 'statutoryagents',
      locationType: 'inhouse',

      projectList: [],
      professionalList: [],
      branchList: [],
      roomList: [],
      tagList: [],
      accountingList: [],
      organizations: [],

      branchMap: {},

      lengthOptions: [30, 60, 90, 120, 150, 180],
      showSpace: false,
      targetFamilyIdx: '',
      showHour: 9,
      origData: undefined,
      remoteEvent: undefined,

    };
  },
  computed: {
    ...mapGetters(['isAdmin']),
    allCanEdit() {
      return this.isAdmin;
    },
    targetName() {
      if (this.target) {
        return `${this.target.family_name}${this.target.given_name}`;
      }
      return '';
    },
    targeID() {
      if (this.target) {
        return this.target.id;
      }
      return -1;
    },
    valid() {
      return true;
    },
  },
  watch: {
    branch() {
      if (this.branch === undefined) {
        this.roomList = [];
        return;
      }
      this.roomList = this.branchMap[this.branch]?.rooms || [];
      if (!this.roomList.reduce((v, room) => (room.id === this.room || v), false)) {
        this.room = undefined;
      }
    },
    project() {
      this.loadProjectDetail();
    },
  },
  methods: {
    handleClose(done) {
      this.$confirm('確定要取消編輯預約?', '提醒', {
        confirmButtonText: '是',
        cancelButtonText: '否，繼續編輯',
        type: 'warning',
        customClass: 'button-revert',
      }).then(() => {
        done();
      }).catch(() => {
        done(false);
      });
    },
    close(result) {
      this.$emit('finish', result);
      this.drawer = false;
    },
    show() {
      this.drawer = true;
    },
    generateAppointment() {
      debugger;
      const start = new Date(this.startDate);
      const [hour, minute] = this.startTime.split(':');
      start.setHours(hour, minute, 0, 0);
      const partners = [];
      if (this.type === 'couple' || this.type === 'parent') {
        partners.push(this.relationalTarget.id);
      } else if (this.type === 'family' || this.type === 'earlytreatment') {
        partners.push(...this.relationalTargets.map((r) => r.id));
      }
      const data = {
        price: parseInt(this.price, 10),
        start_at: this.$dateToTimestamp(start),
        end_at: this.$dateToTimestamp(start) + this.length * 60,
        location_type: this.locationType,
        model_id: this.accounting,
        user_id: this.target.id,
        room_id: this.room,
        professional_id: this.professional,
        appointment_type: this.type,
        project_id: this.project,
        note: this.note,
        meeting_link: this.remoteLink,
        tags: this.tags,
        partners,
      };

      if (this.type === 'parent') {
        data.appointment_subtype = this.subType;
      }

      return data;
    },
    reset() {
      this.drawer = false;
      this.target = undefined;
      this.project = undefined;
      this.professional = undefined;
      this.startDate = undefined;
      this.startTime = '09:00';
      this.branch = undefined;
      this.room = undefined;
      this.accounting = undefined;
      this.price = undefined;
      this.tags = [];
      this.note = '';
      this.cannotEditTarget = false;
      this.origData = undefined;
      this.relationalTarget = undefined;
      this.relationalTargets = [];
    },
    checkInput() {
      const errs = this.$initInputError();
      if (this.target === undefined) {
        errs.add('個案', '請選擇個案');
      }
      if (this.accounting === undefined) {
        errs.add('分帳模組', '請選擇分帳模組');
      }
      if (this.startDate === undefined) {
        errs.add('預約日期', '請選擇預約日期');
      }
      if (this.price === undefined) {
        errs.add('預約價位', '請輸入預約價位');
      }
      if (this.branch === undefined || this.room === undefined) {
        errs.add('場地空間', '請選擇場地空間');
      }

      if (errs.hasError) {
        const msg = errs.warnMessage;
        this.$showWarn(msg.title, msg.message);
        return false;
      }

      return true;
    },
    async save() {
      if (!this.checkInput()) {
        return false;
      }
      return this.$execWithLoading(async () => {
        const appointment = this.generateAppointment();
        const rsp = await updateAppointment(this.origData.id, appointment);
        console.log(rsp);

        if (this.remoteEvent !== undefined) {
          const data = {
            summary: `Meeting ${rsp.id}`,
            start: {
              dateTime: new Date(appointment.start_at * 1000).toISOString(),
              timeZone: 'Asia/Taipei',
            },
            end: {
              dateTime: new Date(appointment.end_at * 1000).toISOString(),
              timeZone: 'Asia/Taipei',
            },
          };
          await this.patchEvent(this.remoteEvent.id, data);
        }
        this.$showSuccess('更新預約成功');
        this.close(true);
      }, (e) => {
        console.log(e);
        if (e?.response?.status === 409) {
          this.$showError('更新預約失敗，請確認場地與時間是否有衝突');
        } else {
          this.$showError('更新預約失敗，請稍後再試');
        }
      });
    },
    async patchEvent(id, data) {
      return new Promise((r) => {
        const request = window.gapi.client.calendar.events.patch({
          calendarId: 'primary',
          eventId: id,
          conferenceDataVersion: 1,
          resource: data,
        });
        request.execute((result) => {
          r(result);
        });
      });
    },
    cancel() {
      this.close(false);
    },
    async loadData() {
      await this.$execWithLoadingMessage('載入選項中', async () => {
        await this.loadDetail();
        await Promise.all([
          this.loadProjects(),
          this.loadProfessionals(),
          this.loadBranchs(),
          this.loadTags(),
          this.loadAccountings(),
        ]);
        this.loadEditData();
      });
    },
    async loadProjects() {
      const rsp = await getOrganizations();
      this.projectOrgMap = {};
      rsp.forEach((org) => {
        org.projects.forEach((p) => {
          this.projectOrgMap[p.id] = org.id;
        });
      });
      this.organizations = rsp;
    },
    async loadProjectDetail() {
      const projectID = this.project;
      const orgID = this.projectOrgMap[projectID];
      if (orgID === undefined) {
        return '';
      }
      return this.$execWithLoadingMessage('載入專案資訊中', async () => {
        const detail = await getOrganizationDetail(orgID);
        const projectDetail = detail.projects.find((p) => p.id.toString() === projectID.toString());
        this.price = projectDetail.price;
        this.length = projectDetail.duration;
      });
    },
    async loadProfessionals() {
      const list = await getProfessionalList();
      this.professionalList = list.filter((p) => p.public).map((p) => ({
        ...p,
        name: `${p.family_name}${p.given_name}`,
      }));
    },
    async loadDetail() {
      const rsp = await getAppointment(this.origData.id);
      this.origData = rsp;
      if (rsp.payslip?.confirm_status === 'confirmed') {
        this.$showWarn('禁止編輯', '該預約對帳月份已確認，不可編輯');
        this.close();
        throw new Error('confirmed_appointment');
      }
    },
    async loadAccountings() {
      const rsp = await getAccountingModels();
      this.accountingList = rsp.filter((a) => a.model_type === 'appointment');

      const accountingMap = {};
      rsp.forEach((a) => {
        accountingMap[a.id] = a;
      });
      this.accountingMap = accountingMap;
    },
    loadEditData() {
      const data = this.origData;

      this.target = data.user;
      this.project = (data.project?.id || '').toString();
      this.professional = data.professional.id;
      this.type = data.appointment_type;
      this.subType = data.appointment_subtype || 'statutoryagents';
      if (this.type === 'couple' || this.type === 'parent') {
        if (data.partners.length > 0) {
          const p = data.partners[0];
          this.relationalTarget = {
            ...p,
            name: `${p.family_name}${p.given_name}`,
          };
          this.relationalTargets = [this.relationalTarget];
        }
      } else if (this.type === 'family' || this.type === 'earlytreatment') {
        this.relationalTargets = data.partners.map((p) => ({
          ...p,
          name: `${p.family_name}${p.given_name}`,
        }));
        if (this.relationalTargets.length > 0) {
          // eslint-disable-next-line prefer-destructuring
          this.relationalTarget = this.relationalTargets[0];
        }
      }
      this.locationType = data.location_type;
      this.remoteLink = data.meeting_link || '';
      this.length = (data.end_at - data.start_at) / 60;
      const startDate = new Date(data.start_at * 1000);
      this.startDate = startDate;
      this.startTime = this.$timestampToTime(startDate);
      this.branch = data.room?.branch?.id;
      this.room = data.room?.id;
      this.accounting = data.model?.id;
      this.price = data.price;
      this.tags = data.tags;
      this.note = data.note || '';
      this.$forceUpdate();
    },
    async loadBranchs() {
      const branches = await getBranches(true);
      this.branchList = branches.filter((b) => b.public);
      const m = {};
      branches.forEach((b) => {
        m[b.id] = b;
      });
      this.branchMap = m;
    },
    async loadTags() {
      const tags = await getTags();
      this.tagList = tags.filter((t) => t.public);
    },
    generateRemoteLink() {
      this.$execWithLoading(async () => {
        const data = await this.handleAuthClick();
        this.remoteLink = data.uri || 'testing-link';
      }, () => {
        this.$showError('生成連結失敗');
      });
    },
    async handleAuthClick() {
      return new Promise((r, reject) => {
        // eslint-disable-next-line no-undef
        const c = tokenClient;
        // eslint-disable-next-line no-undef
        c.callback = async (resp) => {
          if (resp.error !== undefined) {
            throw (resp);
          }
          try {
            const rsp = await this.addEvent();
            r(rsp);
          } catch (e) {
            reject(e);
          }
        };
        c.error_callback = (err) => {
          reject(err);
        };

        if (window.gapi.client.getToken() === null) {
          c.requestAccessToken({ prompt: 'consent' });
        } else {
          c.requestAccessToken({ prompt: '' });
        }
      });
    },
    async addEvent() {
      const now = new Date();
      const end = new Date(now.getTime() + 3600 * 1000);
      const event = {
        summary: 'New Temp Event',
        start: {
          dateTime: now.toISOString(),
          timeZone: 'Asia/Taipei',
        },
        end: {
          dateTime: end.toISOString(),
          timeZone: 'Asia/Taipei',
        },
        conferenceData: {
          createRequest: {
            requestId: new Date().getTime().toString(),
            conferenceSolutionKey: {
              type: 'hangoutsMeet',
            },
            status: {
              statusCode: 'success',
            },
          },
        },
      };

      const request = window.gapi.client.calendar.events.insert({
        calendarId: 'primary',
        conferenceDataVersion: 1,
        resource: event,
      });
      const rsp = await this.getResult(request);
      this.remoteEvent = rsp;
      return rsp.conferenceData.entryPoints[0];
    },
    async getResult(request) {
      return new Promise((r) => {
        request.execute((result) => {
          r(result);
        // patchEvent(event);
        });
      });
    },
    changeDate() {
      this.$refs.timeSelector.$el.querySelector('input').focus();
    },
    toggleRemainSpace() {
      if (this.showSpace === false) {
        let [hour, minute] = this.startTime.split(':');
        hour = parseInt(hour, 10);
        minute = parseInt(minute, 10);
        if (minute !== 0) {
          hour += 0.5;
        }
        this.showHour = hour;
      }
      this.showSpace = !this.showSpace;
    },
    chooseRelation(data) {
      console.log(data);
      this.relationalTarget = data;
    },
    chooseFamilyRelation(idx, data) {
      console.log({ idx, data });
      if (data) {
        this.relationalTargets[idx] = data;
      }
    },
    addNewFamily() {
      this.relationalTargets.push({});
    },
  },
  mounted() {
    this.$on('show', (data) => {
      this.reset();
      this.show();
      this.origData = data;
      setTimeout(() => {
        this.loadData();
      }, 100);
    });
  },
};
</script>

<style lang="scss" scoped>
.drawer {
  padding: 20px;
  padding-top: 0;
  .data {
    .desc {
      font-size: 12px;
    }
    .radio {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
}
.space-view {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 30vw !important;
  height: calc(100vh - 40px) !important;
  height: calc(100dvh - 40px) !important;
}
</style>
