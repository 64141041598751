<template lang="pug">
el-select.appointment_type(
  :disabled="disabled"
  clearable
  v-model="typeValue", placeholder="請選擇預約類型")
  el-option(v-for="e in typeList"
    :key="e.value"
    :value="e.value"
    :label="e.text"
)
</template>

<script>
export default {
  name: 'type-select',
  props: {
    value: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value() {
      if (this.typeValue !== this.value) {
        this.typeValue = this.value;
      }
    },
    typeValue() {
      if (this.typeValue !== this.value) {
        this.$emit('input', this.typeValue);
      }
    },
  },
  data() {
    return {
      typeValue: '',
      typeList: [
        { value: 'individual', text: this.$msg('appointment.type.individual') },
        { value: 'couple', text: this.$msg('appointment.type.couple') },
        { value: 'parent', text: this.$msg('appointment.type.parent') },
        { value: 'family', text: this.$msg('appointment.type.family') },
        { value: 'earlytreatment', text: this.$msg('appointment.type.earlytreatment') },
      ],
    };
  },
  mounted() {
    this.typeValue = this.value;
  },
};
</script>

  <style lang="scss" scoped>

  </style>
