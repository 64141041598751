<template lang="pug">
el-drawer(
  :title="editMode ? titleEdit : titleCreate"
  :visible.sync="drawer"
  direction="rtl"
  :before-close="handleClose"
  :append-to-body="appendToBody")
  .drawer.flex.column.gap-20.default
    .data.flex.align-stretch.gap-6
      .flex.column.align-stretch.gap-6.flex-1
        .title 姓氏
        el-input.input(
          :disabled="proEdit"
          v-model="familyName", placeholder="請輸入姓氏")
      .flex.column.align-stretch.gap-6.flex-1
        .title 名字*
        el-input.input(
          :disabled="proEdit"
          v-model="givenName", placeholder="請輸入名字")
    template(v-if="!proEdit")
      .data.flex.column.align-stretch.gap-6
        .title 生理性別*
        el-select(placeholder="請選擇性別", v-model="gender")
          el-option(label="男", value="male")
          el-option(label="女", value="female")
          el-option(label="其他", value="other")
      .data.flex.column.align-stretch.gap-6
        .title 稱謂
        el-input.input(v-model="called", placeholder="請輸入稱謂")
      .data.flex.column.align-stretch.gap-6
        .title 出生年月日*
        .input.flex.align-center
          el-date-picker.flex-1(
            placeholder="請選擇日期"
            v-model="birth")
      .data.flex.column.align-stretch.gap-6
        .title 身分證字號
        el-input.input(v-model="idNumber", placeholder="請輸入身分證字號")
      .data.flex.column.align-stretch.gap-6
        .title 護照號碼
        el-input.input(v-model="passport", placeholder="請輸入護照號碼")
      .data.flex.align-center.justify-stretch.gap-10
        .left.flex.column.align-stretch.gap-6
          .title 國碼
          el-select(v-model="phoneCountry"
              filterable)
            el-option(
              v-for="country in countryList"
              :key="country.code"
              :value="country.dial_code"
              :label="`${country.code} ${country.dial_code}`"
            )
        .right.flex.column.align-stretch.gap-6
          .title 聯絡電話
          el-input(v-model="phone")
      .data.flex.column.align-stretch.gap-6
        .title Email*
        //- .alert.text-danger(v-if="showEmailAlert") 變更 email 需重新進行雙重驗證的綁定
        el-input.input(
          v-model="email", placeholder="請輸入 Email")
      .data.flex.column.align-stretch.gap-6
        .title 所在國家
        el-select(
          clearable
          v-model="country"
          @change="county = ''")
          el-option(
            v-for="country in countryList"
            :key="country.code"
            :value="country.code"
            :label="`${country.flag} ${country.name}`"
          )
      .data.flex.column.align-stretch.gap-6(v-if="country === 'TW'")
        .title 縣市
        el-input(v-if="country && country !== 'TW'", v-model="county")
        el-select.input(
          v-else
          clearable
          autocomplete="off"
          filterable
          v-model="county" placeholder="請輸入縣市")
          el-option(
            v-for="county in counties"
            :key="county"
            :label="county"
            :value="county")
      .data.flex.column.align-stretch.gap-6(v-if="country === 'TW'")
        .title 鄉鎮區
        el-input(v-if="country && country !== 'TW'", v-model="district")
        el-select.input(
          v-else
          clearable
          filterable
          :disabled="county === undefined || county === ''"
          v-model="district" placeholder="請輸入鄉鎮")
          el-option(
            v-for="d in districts"
            :key="d"
            :label="d"
            :value="d")
      .data.flex.column.align-stretch.gap-6
        .title 地址
        el-input.input(v-model="address", placeholder="請輸入地址")
      .data.flex.column.align-stretch.gap-6
        .title 感情狀態
        relation-select.input(v-model="relationship")
      .data.flex.column.align-stretch.gap-6
        .title 最高學歷
        education-select.input(v-model="education")
      .data.flex.column.align-stretch.gap-6
        .title 產業
        el-select(
          clearable
          placeholder="請輸入產業", v-model="industry")
          el-option(v-for="j in jobList", :key="j", :label="j", :value="j")
      .data.flex.column.align-stretch.gap-6
        .title 職業
        el-input(v-model="job", placeholder="請輸入職業")
    .data.flex.column.align-stretch.gap-6
      .title 職務狀態
      el-select(v-model="jobPosition", placeholder="請輸入職務狀態")
        el-option(label="約聘員工", value="約聘員工")
        el-option(label="基層員工", value="基層員工")
        el-option(label="基層主管", value="基層主管")
        el-option(label="中階主管", value="中階主管")
        el-option(label="高階主管", value="高階主管")
        el-option(label="其他", value="其他")
      //- el-input(v-model="jobPosition", placeholder="請輸入職務狀態")
    .data.flex.column.align-stretch.gap-6
      .title 工作年資
      el-select(v-model="jobTenure", placeholder="請輸入工作年資")
        el-option(label="1年以下", value="1年以下")
        el-option(label="1年以上，未滿3年", value="1年以上，未滿3年")
        el-option(label="3年以上，未滿5年", value="3年以上，未滿5年")
        el-option(label="5年以上，未滿7年", value="5年以上，未滿7年")
        el-option(label="7年以上，未滿9年", value="7年以上，未滿9年")
        el-option(label="9年以上", value="9年以上")
      //- el-input(v-model="jobTenure", placeholder="請輸入工作年資")
    template(v-if="!proEdit")
      .data.flex.align-center.justify-stretch.gap-10
        .flex.column.align-stretch.gap-6.flex-1
          .title 緊急聯絡人*
          el-input(v-model="contact", placeholder="請輸入緊急聯絡人")
        .flex.column.align-stretch.gap-6.flex-1
          .title &nbsp;
          el-input(v-model="contactRelation", placeholder="請輸入關係")
      .data.flex.align-center.justify-stretch.gap-10
        .left.flex.column.align-stretch.gap-6
          .title 國碼*
          el-select(v-model="contactPhoneCountry"  filterable)
            el-option(
              v-for="country in countryList"
              :key="country.code"
              :value="country.dial_code"
              :label="`${country.code} ${country.dial_code}`"
            )
        .right.flex.column.align-stretch.gap-6
          .title 聯絡電話*
          el-input(v-model="contactPhone")
      .data.flex.column.align-stretch.gap-6
        .title 語言
        el-input.input(v-model="language", placeholder="請輸入語言")
      .data.flex.column.align-stretch.gap-6
        .title 案件來源
        el-input.input(v-model="source", placeholder="請輸入案件來源")
      .data.flex.column.align-stretch.gap-6
        .title 就醫紀錄/諮商紀錄
        el-input.input(v-model="record", placeholder="請輸入文字")
      .data.flex.column.align-stretch.gap-6
        .title 是否嘗試過自殺
        el-input.input(v-model="suiside", placeholder="請輸入文字")
    .op.flex.align-center-justify-stretch.gap-10
      el-button.button(@click="cancel") 取消
      template(v-if="editMode")
        el-button.button(@click="save", type="primary", :disabled="!valid") 更新
      template(v-else)
        el-button.button(@click="save", type="primary", :disabled="!valid") 新增
</template>

<script>
import { getList } from 'country-list-with-dial-code-and-flag';
import {
  createUser,
  updateUser,
  updateProUser,
} from '@/api/user';
import {
  getCounties,
  getDistricts,
} from '@/util/address';
import constant from '@/util/constant';

export default {
  name: 'person-editor',
  props: {
    appendToBody: {
      type: Boolean,
      default: false,
    },
    proEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const countries = getList();
    countries.sort((a, b) => (a.name > b.name ? 1 : -1));
    const idx = countries.findIndex((c) => c.dial_code === '+886');
    const tw = countries[idx];
    tw.name = 'Taiwan';
    countries.splice(idx, 1);
    countries.unshift(tw);

    const counties = getCounties();
    return {
      drawer: false,
      editMode: false,
      origData: undefined,
      titleEdit: '編輯個案',
      titleCreate: '新增個案',
      countryList: countries,
      jobList: constant.jobList,
      counties,

      familyName: '',
      givenName: '',
      gender: '',
      birth: undefined,
      idNumber: '',
      passport: '',
      phoneCountry: '+886',
      phone: '',
      email: '',
      country: 'TW',
      county: '',
      district: '',
      address: '',
      relationship: undefined,
      education: undefined,
      industry: '',
      job: '',
      jobPosition: '',
      jobTenure: '',
      contact: '',
      contactRelation: '',
      contactPhoneCountry: '+886',
      contactPhone: '',
      language: '',
      source: '',
      record: '',
      suiside: '',
      called: '',
    };
  },
  computed: {
    valid() {
      return true;
    },
    districts() {
      return this.getDistricts(this.county);
    },
    showEmailAlert() {
      return this.email !== this.origData?.email;
    },
  },
  methods: {
    getDistricts,
    handleClose(done) {
      this.reset();
      done();
    },
    close(result) {
      this.$emit('finish', result);
      this.drawer = false;
      this.reset();
    },
    cancel() {
      this.close(false);
    },
    checkInput() {
      const errs = this.$initInputError();
      // if (this.familyName === '') {
      //   errs.add('姓氏', '請輸入姓氏');
      // }
      if (this.givenName === '') {
        errs.add('名字', '請輸入名字');
      }
      if (this.email === '') {
        errs.add('Email', '請輸入 Email 信箱');
      }
      // if (this.gender === '') {
      //   errs.add('生理性別', '請輸入生理性別');
      // }
      // if (this.birth === undefined) {
      //   errs.add('生日', '請輸入生日');
      // }
      // if (this.idNumber !== '' && !validateID(this.idNumber)) {
      //   errs.add('身分證字號', '身分證字號格式錯誤');
      // }
      // if (this.idNumber === '' && this.passport === '') {
      //   errs.add('身分證字號與護照號碼', '身分證字號與護照號碼至少擇一填寫');
      // }
      // if (!(
      //   (this.contact === '' && this.contactRelation === '' && this.contactPhone === '')
      //   || (this.contact !== '' && this.contactRelation !== '' && this.contactPhone !== ''))) {
      //   errs.add('緊急聯絡人', '緊急聯絡人的關係與電話必須都填寫');
      // }

      if (errs.hasError) {
        const msg = errs.warnMessage;
        this.$showWarn(msg.title, msg.message);
        return false;
      }

      return true;
    },
    save() {
      if (!this.proEdit && !this.checkInput()) {
        return;
      }
      let user = this.generateUserObject();
      this.$execWithLoading(async () => {
        if (this.editMode) {
          let func = updateUser;
          console.log(this.proEdit);
          if (this.proEdit) {
            user = this.generateProUserObject();
            func = updateProUser;
          }
          await func(
            this.origData.id, user,
          );
          this.$showSuccess('更新成功');
          this.close(user);
        } else {
          await createUser(user);
          this.$showSuccess('新增成功');
          this.close(user);
        }
      }, (e) => {
        if (this.editMode) {
          this.$showAxiosException('更新失敗', e);
        } else {
          this.$showAxiosException('新增失敗', e);
        }
      });
    },
    generateUserObject() {
      return {
        family_name: this.familyName,
        given_name: this.givenName,
        gender: this.gender,
        birthday: this.$timestampToDate(this.birth).replace(/\//g, '-'),
        id_number: this.idNumber,
        passport: this.passport,
        phone: this.$getPhone(this.phoneCountry, this.phone),
        email: this.email,
        country: this.country,
        county: this.county,
        district: this.district,
        address: this.address,
        relationship: this.relationship,
        education: this.education,
        industry: this.industry,
        job: this.job,
        job_position: this.jobPosition,
        job_tenure: this.jobTenure,
        emergency_person: this.contact,
        emergency_relationship: this.contactRelation,
        emergency_contact: this.$getPhone(this.contactPhoneCountry, this.contactPhone),
        prefer_language: this.language,
        refer_source: this.source,
        therapy_history: this.record,
        suicide_record: this.suiside,
        wanna_be_called: this.called,
        line_chat_id: this.origData?.line_chat_id || '',
      };
    },
    generateProUserObject() {
      return {
        job_position: this.jobPosition,
        job_tenure: this.jobTenure,
      };
    },
    reset() {
      this.familyName = '';
      this.givenName = '';
      this.gender = '';
      this.birth = undefined;
      this.idNumber = '';
      this.passport = '';
      this.phoneCountry = '+886';
      this.phone = '';
      this.email = '';
      this.country = 'TW';
      this.county = '';
      this.district = '';
      this.address = '';
      this.relationship = undefined;
      this.education = undefined;
      this.job = '';
      this.jobPosition = '';
      this.jobTenure = '';
      this.contact = '';
      this.contactRelation = '';
      this.contactPhoneCountry = '+886';
      this.contactPhone = '';
      this.language = '';
      this.souce = '';
      this.record = '';
      this.suiside = '';
      this.called = '';
    },
    show(input) {
      this.drawer = true;
      if (input === undefined) {
        this.editMode = false;
        return;
      }
      this.editMode = true;
      if (input.type === 'id') {
        console.log('TODO');
      } else {
        this.loadData(input.data);
      }
    },
    loadData(data) {
      this.origData = data;
      this.familyName = data.family_name;
      this.givenName = data.given_name;
      this.gender = data.gender || '';
      this.birth = data.birthday ? new Date(data.birthday) : undefined;
      this.idNumber = data.id_number || '';
      this.passport = data.passport || '';
      [this.phoneCountry, this.phone] = this.$splitPhone(data.phone);
      this.email = data.email || '';
      this.country = data.country || '';
      this.county = data.county || '';
      this.district = data.district || '';
      this.address = data.address || '';
      this.relationship = data.relationship || '';
      this.education = data.education || '';
      this.job = data.job || '';
      this.jobPosition = data.job_position || '';
      this.jobTenure = data.job_tenure || '';
      this.contact = data.emergency_person || '';
      this.contactRelation = data.emergency_relationship || '';
      [this.contactPhoneCountry, this.contactPhone] = this.$splitPhone(data.emergency_contact);
      this.language = data.prefer_language;
      this.source = data.refer_source;
      this.record = data.therapy_history;
      this.suiside = data.suicide_record;
      this.called = data.wanna_be_called;
    },
  },
  mounted() {
    this.$on('show', (input) => {
      this.show(input);
    });
  },
};
</script>

<style lang="scss" scoped>
.drawer {
  padding: 0 20px;
  padding-bottom: 20px;
  .data {
    .left {
      flex: 0 0 120px;
    }
    .right {
      flex: 1;
    }
  }
  .button {
    flex: 1;
  }
}
</style>
