export function paddingToN(num, n) {
  if (num.toString().length >= n) {
    return num.toString();
  }
  const zeroCount = n - num.toString().length;
  const ret = String('0');
  return `${ret.repeat(zeroCount)}${num}`;
}

// convert timestamp to YYYY/MM/DD HH:mm:ss
export function timestampToDateTime(ts, dft) {
  let d;
  if (ts instanceof Date) {
    d = new Date(ts);
  } else {
    let timestamp = parseInt(ts, 10);
    if (timestamp.toString().length <= 10) {
      timestamp *= 1000;
    }
    d = new Date(timestamp);
  }

  if (Number.isNaN(d.getTime())) {
    return dft || '';
  }
  return `${
    paddingToN(d.getFullYear(), 4)
  }/${
    paddingToN(d.getMonth() + 1, 2)
  }/${
    paddingToN(d.getDate(), 2)
  } ${
    paddingToN(d.getHours(), 2)
  }:${
    paddingToN(d.getMinutes(), 2)
  }:${
    paddingToN(d.getSeconds(), 2)
  }`;
}

// convert timestamp to YYYY/MM/DD HH:mm
export function timestampToDateTimeMinute(ts, dft) {
  let d;
  if (ts instanceof Date) {
    d = new Date(ts);
  } else {
    let timestamp = parseInt(ts, 10);
    if (timestamp.toString().length <= 10) {
      timestamp *= 1000;
    }
    d = new Date(timestamp);
  }

  if (Number.isNaN(d.getTime())) {
    return dft || '';
  }
  return `${
    paddingToN(d.getFullYear(), 4)
  }/${
    paddingToN(d.getMonth() + 1, 2)
  }/${
    paddingToN(d.getDate(), 2)
  } ${
    paddingToN(d.getHours(), 2)
  }:${
    paddingToN(d.getMinutes(), 2)
  }`;
}

const dayText = {
  0: '日',
  1: '一',
  2: '二',
  3: '三',
  4: '四',
  5: '五',
  6: '六',
};

// convert timestamp to YYYY/MM/DD (D) HH:mm
export function timestampToDateTimeMinuteWithDay(ts, dft) {
  let d;
  if (ts instanceof Date) {
    d = new Date(ts);
  } else {
    let timestamp = parseInt(ts, 10);
    if (timestamp.toString().length <= 10) {
      timestamp *= 1000;
    }
    d = new Date(timestamp);
  }

  if (Number.isNaN(d.getTime())) {
    return dft || '';
  }
  return `${
    paddingToN(d.getFullYear(), 4)
  }/${
    paddingToN(d.getMonth() + 1, 2)
  }/${
    paddingToN(d.getDate(), 2)
  } (${
    dayText[d.getDay()]
  }) ${
    paddingToN(d.getHours(), 2)
  }:${
    paddingToN(d.getMinutes(), 2)
  }`;
}

// convert timestamp to HH:mm
export function timestampToTime(ts, dft) {
  let d;
  if (ts instanceof Date) {
    d = new Date(ts);
  } else {
    let timestamp = parseInt(ts, 10);
    if (timestamp.toString().length <= 10) {
      timestamp *= 1000;
    }
    d = new Date(timestamp);
  }

  if (Number.isNaN(d.getTime())) {
    return dft || '';
  }
  return `${
    paddingToN(d.getHours(), 2)
  }:${
    paddingToN(d.getMinutes(), 2)
  }`;
}

// convert timestamp to HH:mm AM
export function timestampToTimeM(ts, dft) {
  let d;
  if (ts instanceof Date) {
    d = new Date(ts);
  } else {
    let timestamp = parseInt(ts, 10);
    if (timestamp.toString().length <= 10) {
      timestamp *= 1000;
    }
    d = new Date(timestamp);
  }

  if (Number.isNaN(d.getTime())) {
    return dft || '';
  }
  let isAM = true;
  let hour = d.getHours();
  if (hour >= 12) {
    hour %= 12;
    isAM = false;
  }
  return `${
    paddingToN(d.getHours(), 2)
  }:${
    paddingToN(d.getMinutes(), 2)
  } ${isAM ? 'AM' : 'PM'}`;
}

// convert timestamp AM
export function timestampToM(ts, dft) {
  let d;
  if (ts instanceof Date) {
    d = new Date(ts);
  } else {
    let timestamp = parseInt(ts, 10);
    if (timestamp.toString().length <= 10) {
      timestamp *= 1000;
    }
    d = new Date(timestamp);
  }

  if (Number.isNaN(d.getTime())) {
    return dft || '';
  }
  let isAM = true;
  let hour = d.getHours();
  if (hour > 12) {
    hour %= 12;
    isAM = false;
  }
  return isAM ? 'AM' : 'PM';
}

export function timestampToDate(ts) {
  let d;
  if (ts instanceof Date) {
    d = new Date(ts);
  } else {
    let timestamp = parseInt(ts, 10);
    if (timestamp.toString().length <= 10) {
      timestamp *= 1000;
    }
    d = new Date(timestamp);
  }

  if (Number.isNaN(d.getTime())) {
    return '';
  }
  return `${
    paddingToN(d.getFullYear(), 4)
  }/${
    paddingToN(d.getMonth() + 1, 2)
  }/${
    paddingToN(d.getDate(), 2)
  }`;
}

// convert timestamp to YYYY/MM/DD (D) HH:mm
export function timestampToDateWithDay(ts, dft) {
  let d;
  if (ts instanceof Date) {
    d = new Date(ts);
  } else {
    let timestamp = parseInt(ts, 10);
    if (timestamp.toString().length <= 10) {
      timestamp *= 1000;
    }
    d = new Date(timestamp);
  }

  if (Number.isNaN(d.getTime())) {
    return dft || '';
  }
  return `${
    paddingToN(d.getFullYear(), 4)
  }/${
    paddingToN(d.getMonth() + 1, 2)
  }/${
    paddingToN(d.getDate(), 2)
  } (${
    dayText[d.getDay()]
  })`;
}

export function isValidEmail(email) {
  // eslint-disable-next-line no-control-regex
  const regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  return email.match(regex) !== null;
}

export function toCurrencyNumber(input) {
  const value = parseInt(input, 10);
  const sign = value >= 0 ? '' : '-';

  const numbers = Math.abs(value).toString().split('');
  numbers.reverse();
  const results = numbers.reduce((ret, n, idx) => {
    console.log(n, idx);
    if (idx !== 0 && idx % 3 === 0) {
      return `${ret},${n}`;
    }
    return `${ret}${n}`;
  }, '').split('');
  results.reverse();
  return `${sign}${results.join('')}`;
}
